<template>
  <a-layout class='s_bf'>
    <div class="mb15">
      <span class='mr15'>
        <a-button type="primary" v-has:invisibleAdd="()=>{$router.push('/device/invisibleAdd?type=add')}" class="m-r-10">
          <PlusOutlined />
          添加设备
        </a-button>
      </span>
    </div>
    <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
      :onFinish='pageSearchChange' layout='inline'>
      <a-form-item label="设备号" name='imei'>
        <a-input v-model:value="search.imei" style="width:250px" placeholder="请输入设备号进行搜索"></a-input>
      </a-form-item>
      <a-form-item label="所属品牌" name='brand'>
        <a-input v-model:value="search.brand" style="width:250px" placeholder="请输入品牌名称进行搜索"></a-input>
      </a-form-item>
      <a-form-item label="状态" name='status'>
        <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
          <a-select-option :value="1">启用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="创建时间" name='time'>
        <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType='submit'>搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
      <template v-slot:status="{ record }">
        <a-switch v-model:checked="record.status" :checkedValue="1" :unCheckedValue="0"  checked-children="启用" un-checked-children="禁用" v-authAction:invisibleStatus="()=>switchChange(record)"></a-switch>
      </template>
      <template v-slot:action="{ record }">
        <a-button type="link" v-has:invisibleEdit="()=>{$router.push('/device/invisibleEdit?type=edit&id='+record.id)}">编辑</a-button>
        <a-button type="link" v-has:invisibleDel="()=>delCode(record)">删除</a-button>
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
      :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
      @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-layout>
  <!-- 创建激活码 -->
  <a-modal v-model:visible="visible" title="添加设备" :width="800" destroyOnClose centered :footer="null">
    <invisibleAdd @onsubmit="visible=false;initData();"></invisibleAdd>
  </a-modal>
</template>
<script>
  import { PlusOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import {
    invisibleList,
    delInvisibleCode,
    InvisibleStatus
  } from "@/api/pda.js";
  import invisibleAdd from "./invisibleAdd";

  // 初始化默认筛选项数值
  let defSearch = {
    status: "",
    brand: "",
    imei:"",
    create_start_time: "",
    create_end_time: "",
    page: 1,
    limit: 10,
  };
  export default {
    components: { PlusOutlined,invisibleAdd },
    setup() {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        visible:false,
        listLoading: false,
        total: 0
      });
      let columns = [
        {
          title: "所属品牌",
          dataIndex: "brand_name",
          key: "brand_name",
        },
        {
          title: "设备号",
          dataIndex: "imei",
          key: "imei",
        },
        {
          title: "设备状态",
          dataIndex: "status",
          key: "status",
          slots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "操作",
          key: "action",
          width:160,
          fixed:"right",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject("$Modal");
      // 时间筛选
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.create_start_time = e[0];
          search.value.create_end_time = e[1];
        } else {
          search.value.create_start_time = "";
          search.value.create_end_time = "";
        }
      };
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData();
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData();
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData();
      };

      const initData = async () => {
        state.listLoading = true;
        data.value = [];
        try {
          let res = await invisibleList({...search.value}).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      // 删除
      const delCode = (data) => {
        $Modal.confirm({
          title: "您确定要删除该设备吗？",
          centered:true,
          onOk: async () => {
            let res = await delInvisibleCode(data.id).then((res) => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData();
            } else {
              message.error(res.msg);
            }
          },
        });
      };
      const switchChange=async (e)=>{
        let res = await InvisibleStatus({id:e.id,status:e.status?1:0}).then(res => res.data);
        if ($iscode(res)) {
          message.success(res.msg);
        } else {
          message.error(res.msg);
        }
        initData();
      }
      onMounted(() => {
        initData();
      });

      return {
        ...toRefs(state),
        search,
        data,
        columns,
        onChange,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        delCode,
        initData,
        switchChange
      };
    },
  };
</script>